








import { Component, Vue } from 'vue-property-decorator'
import __ from '@/shared/helpers/__'
import PageHeader from '@/admin/components/PageHeader.vue'
import Form from '@/shared/components/form/Form.vue'
import FormBase from '@/shared/classes/form/form-base'
import { AdminRoutes } from '@/shared/router/admin'
import Company from '@/shared/modules/company/company.model'
import { HttpMethod } from '@/shared/helpers/requests.helper'
import Field, { FieldSizes } from '@/shared/classes/form/field'
import { FieldTypes } from '@/shared/components/form/field-types'
import { GlobalActions } from '@/shared/store/global/global.actions'
import { SnackBarTypes } from '@/shared/helpers/snack-bar.helper'
import SearchableField from '@/shared/classes/form/fields/Select/searchable-field'
import SelectField from '@/shared/classes/form/fields/Select/select-field'
import SelectItem from '@/shared/classes/form/fields/Select/select-item'
import FormGroup from "@/shared/classes/form/group";

@Component({
  components: { Form, PageHeader },
  methods: { __ }
})
export default class CompaniesEdit extends Vue {
  static GROUP_DETAILS = 'details'
  static GROUP_MODULES = 'modules'

  form: FormBase | null = null

  created() {
    this.form = new FormBase()
      .setUuid(this.$router.currentRoute.params.uuid)
      .setModel(Company)
      .setEndpoint('/companies')
      .setFiles(true)
      .setGroups([
        new FormGroup()
          .setKey(CompaniesEdit.GROUP_DETAILS),
        new FormGroup()
          .setKey(CompaniesEdit.GROUP_MODULES)
          .setTitle(__('admin.views.companies.form.groups.modules')),
      ])
      .setFields([
        new Field()
          .setGroup(CompaniesEdit.GROUP_DETAILS)
          .setType(FieldTypes.file)
          .setKey('logo')
          .setTitle(__('admin.views.companies.form.logo'))
          .setMeta({ type: 'image', accept: 'image/*' }),
        new Field()
          .setGroup(CompaniesEdit.GROUP_DETAILS)
          .setKey('name')
          .setTitle(__('admin.views.companies.form.name')),
        new Field()
          .setGroup(CompaniesEdit.GROUP_DETAILS)
          .setKey('slug')
          .setTitle(__('admin.views.companies.form.slug.placeholder'))
          .setHint(__('admin.views.companies.form.slug.hint'))
          .setBeforeChange((value: string): string => value.toLowerCase()
            .replace(/ /g,'-')
            .replace(/[^\w-]+/g,'')
          ),
        new Field()
          .setGroup(CompaniesEdit.GROUP_DETAILS)
          .setType(FieldTypes.textArea)
          .setKey('description')
          .setTitle(__('admin.views.companies.form.description')),
        new Field()
          .setGroup(CompaniesEdit.GROUP_DETAILS)
          .setKey('company_code')
          .setEntryKey('companyCode')
          .setTitle(__('admin.views.companies.form.company-code'))
          .setSize(FieldSizes.half),
        new Field()
          .setGroup(CompaniesEdit.GROUP_DETAILS)
          .setKey('vat_code')
          .setEntryKey('vatCode')
          .setTitle(__('admin.views.companies.form.vat-code'))
          .setSize(FieldSizes.half),
        new Field()
          .setGroup(CompaniesEdit.GROUP_DETAILS)
          .setKey('address')
          .setTitle(__('admin.views.companies.form.address'))
          .setSize(FieldSizes.threeQuarters),
        new Field()
          .setGroup(CompaniesEdit.GROUP_DETAILS)
          .setKey('post_code')
          .setEntryKey('postCode')
          .setTitle(__('admin.views.companies.form.post-code'))
          .setSize(FieldSizes.quarter),
        new Field()
          .setGroup(CompaniesEdit.GROUP_DETAILS)
          .setKey('city')
          .setTitle(__('admin.views.companies.form.city'))
          .setSize(FieldSizes.half),
        new SearchableField()
          .setGroup(CompaniesEdit.GROUP_DETAILS)
          .setKey('country_uuid')
          .setEntryKey('countryUuid')
          .setTitle(__('admin.views.companies.form.country'))
          .loadItems({ endpoint: '/countries', value: 'uuid', title: 'name', perPage: 20 })
          .setSize(FieldSizes.half),
        new Field()
          .setGroup(CompaniesEdit.GROUP_DETAILS)
          .setKey('website')
          .setTitle(__('admin.views.companies.form.website')),
        new SelectField()
          .setGroup(CompaniesEdit.GROUP_DETAILS)
          .setEntryKey('breakMinutes')
          .setKey('break_minutes')
          .setTitle(__('admin.views.companies.form.break'))
          .setItems([
            new SelectItem().setTitle('60 min.').setValue(60),
            new SelectItem().setTitle('30 min.').setValue(30),
            new SelectItem().setTitle('0 min.').setValue('0'),
          ])
          .setSize(FieldSizes.half),
        new Field()
          .setGroup(CompaniesEdit.GROUP_DETAILS)
          .setType(FieldTypes.number)
          .setKey('work_hours_per_day')
          .setEntryKey('workHoursPerDay')
          .setTitle(__('admin.views.companies.form.work-hours-per-day'))
          .setSize(FieldSizes.half)
          .setDisabled(true),
        new Field()
          .setGroup(CompaniesEdit.GROUP_DETAILS)
          .setType(FieldTypes.number)
          .setKey('time_registration_availability')
          .setEntryKey('timeRegistrationAvailability')
          .setTitle(__('admin.views.companies.form.time-registration-availability'))
          .setSize(FieldSizes.half),
        new Field()
          .setGroup(CompaniesEdit.GROUP_DETAILS)
          .setKey('phone')
          .setTitle(__('admin.views.companies.form.phone'))
          .setSize(FieldSizes.half),
        new Field()
          .setGroup(CompaniesEdit.GROUP_MODULES)
          .setType(FieldTypes.checkbox)
          .setKey('is_tools_visible')
          .setEntryKey('isToolsVisible')
          .setTitle(__('admin.views.companies.form.is-tools-visible'))
          .setSize(FieldSizes.threeTwelfth),
        new Field()
          .setGroup(CompaniesEdit.GROUP_MODULES)
          .setType(FieldTypes.checkbox)
          .setKey('is_hse_visible')
          .setEntryKey('isHseVisible')
          .setTitle(__('admin.views.companies.form.is-hse-visible'))
          .setSize(FieldSizes.threeTwelfth),
        new Field()
          .setGroup(CompaniesEdit.GROUP_MODULES)
          .setType(FieldTypes.checkbox)
          .setKey('is_time_accounting_visible')
          .setEntryKey('isTimeAccountingVisible')
          .setTitle(__('admin.views.companies.form.is-time-accounting-visible'))
          .setSize(FieldSizes.half),
      ])
      .setValidate((data: any) => {
        if (!data.is_tools_visible && !data.is_hse_visible && !data.is_time_accounting_visible) {
          this.$store.dispatch(GlobalActions.showSnackBar, {
            type: SnackBarTypes.error,
            message: __("admin.views.companies.form.errors.pick-module"),
          });

          return false
        }

        return true
      })
      .setSubmit({
        text: __('admin.views.companies.edit.form.submit')
      })
      .setOnSuccess(this.onSuccess)
  }

  goBack(): void {
    this.$router.push({ name: AdminRoutes.companiesIndex })
  }

  private onSuccess(): void {
    this.$store.dispatch(GlobalActions.showSnackBar, {
      type: SnackBarTypes.success,
      message: __('admin.views.companies.edit.form.on-success'),
    })
    this.goBack()
  }
}
